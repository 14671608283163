import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortFolio from "../components/shared/portfolio/portfolio"

import "../styles/games.scss"
import "../styles/game-page.scss"
import "../styles/main-page.scss"
import "../styles/publishing.scss"
import "../styles/fund.scss"

// Videos
import fund_video from "../videos/fund.mp4"

class HomePage extends React.Component {

  render(){

    return(
      <Layout>

        <SEO title="Fund"/>
        <Link to="/" className="logo">Super!com</Link>
        <h2 className="title">Fund</h2>
        

        <div className="main-page">

          <div className="scroll-arrow"></div>
        
          <section className="main-page__hero">

            <div className="main-page__hero__video">
              <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                <source src={fund_video} type="video/mp4"/>
              </video>
            </div>

            <div className="center -fund">

              <h2 className="main-page__hero__title -to-left">
                SuperFund is <span className="main-page__hero__title__accent">the investment wing</span> of Super. It targets companies that create engaging content.
              </h2>
              <p className="main-page__hero__message -to-left">
                We equip teams with the tools to turn their studios and projects into successful long-term business ventures with an increasing market value.
              </p>
            
            </div>

          </section>

        </div>


        <div className="main-content -no-background">


          <section className="main-page-section -gradient-background -type-1">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  Proliferate <br/>
                  Your Game Studio
                </span>
              </h4>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">
              
                <div className="prolife">

                  <p className="prolife__text">
                    We invest in studios in the early stages, focusing on those with PC and console game titles. Our objective is to grow the valuation of studios with a typical initial investment of $1 million, with potential future rounds of financing.
                  </p>
                  <p className="prolife__text">
                    Super targets game titles in the action genre that blend traditional game mechanics with a fresh, innovative twist. We cherish studios that can shape unforgettable player experiences. Our vision is to help these passionate developers grow their IP and studios into successful industry leaders.
                  </p>

                  <div className="prolife__footer">
                    <a href="#games-we-invest-in" className="prolife__footer__button">games we invest in</a>
                    <a href="#gametech-portfolio" className="prolife__footer__button">gametech portfolio</a>
                  </div>

                </div>

              </div>

            </div>

          </section>


          <section className="main-page-section -no-margin-top -gradient-background -type-2 -gamepad-background">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  Investment Principles
                </span>
              </h4>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">
              
                <div className="investment">
                  <div className="investment__item -one">
                    <h5 className="investment__item__title">Future Focused</h5>
                    <p className="investment__item__text">
                      We have long-term goals, focusing on growing game projects into successful franchises.
                    </p>
                  </div>
                  <div className="investment__item -two">
                    <h5 className="investment__item__title">Early-stage investments</h5>
                    <p className="investment__item__text">
                      From concept to pre-production, we are passionate about finding the gems in the gaming industry at early stages.
                    </p>
                  </div>
                  <div className="investment__item -three">
                    <h5 className="investment__item__title">Creative Freedom</h5>
                    <p className="investment__item__text">
                      With Super, developers are unencumbered by any restrictions on their creative process. We only keep track of the deliverables. You control the game, and we support you financially.
                    </p>
                  </div>
                </div>

              </div>

            </div>

          </section>


          <section className="main-page-section -no-margin-top -gradient-background -type-2">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  How We Can Help
                </span>
              </h4>

              <p className="main-page-section__message -wide">
                As an equity fund, we support your game from many angles. We help build networks in the industry, showcase your game to prominent experts, facilitate additional financing, and much more.
              </p>

            </div>

            <div className="center">

              <div className="main-page-section__content">
              
                <div className="how">

                  <h5 className="how__title"><span className="how__title__text">Investments and Networking</span></h5>

                  <div className="how__content -three">
                    <div className="how__item -scheme">
                      <h6 className="how__item__title">Sourcing investment</h6>
                      <p className="how__item__text">
                        Securing the necessary resources to make your project prosper is difficult. We work hand-in-hand with investors, sparking their interest in your project and delivering financial support to satiate nearly every budget.
                      </p>
                    </div>
                    <div className="how__item -flower">
                      <h6 className="how__item__title">Relationships with publishers, platforms and gaming companies</h6>
                      <p className="how__item__text">
                        We have deep roots in the video game industry. We can plant seeds for your growth, pitching your game to premium-grade publishers or securing special conditions from stores and platforms.
                      </p>
                    </div>
                    <div className="how__item -square-arrows">
                      <h6 className="how__item__title">Game documentation</h6>
                      <p className="how__item__text">
                        Are you lacking game documentation? We can help prepare the proper materials to ensure that you excel in reaching your goals.
                      </p>
                    </div>
                  </div>

                </div>
              
                <div className="how">

                  <h5 className="how__title"><span className="how__title__text">Your Game</span></h5>

                  <div className="how__content -two">
                    <div className="how__item -funnel">
                      <h6 className="how__item__title">Evaluate your game's potential for its target audience</h6>
                      <p className="how__item__text">
                        Want to know how your game will perform before a soft launch? Say no more. We will arrange playtests and collect user feedback to gauge revenue estimates.
                      </p>
                    </div>
                    <div className="how__item -pedestal">
                      <h6 className="how__item__title">An advisory board of top gaming experts</h6>
                      <p className="how__item__text">
                        Super provides an elite squadron of battle-hardened industry veterans who provide consultation regarding development, production, and the marketing behind your title.
                      </p>
                    </div>
                  </div>

                </div>
              
                <div className="how">

                  <h5 className="how__title"><span className="how__title__text">Your Studio</span></h5>

                  <div className="how__content -three">
                    <div className="how__item -person-shield">
                      <h6 className="how__item__title">Legal setup</h6>
                      <p className="how__item__text">
                        Solid legal processes and documentation are essential en route to a successful game studio. We will assist you through the whole process – be it incorporation of the studio or drafting equity financing agreements.
                      </p>
                    </div>
                    <div className="how__item -persons-app">
                      <h6 className="how__item__title">Worldwide HR</h6>
                      <p className="how__item__text">
                        Dream of hiring that rockstar engineer skilled in designing voxel rendering engines? We help find them and put them on your team.
                      </p>
                    </div>
                    <div className="how__item -person-key">
                      <h6 className="how__item__title">Establishing a back office</h6>
                      <p className="how__item__text">
                        Running a game studio can turn into a nightmare if the proper processes are not in place. We will provide guidance and resources for setting up a back office for your venture.
                      </p>
                    </div>
                  </div>

                </div>

                <p className="how-text">
                  Our philosophy stems from an understanding of what it takes to be popular and successful. No one wants to wait for the next best thing. We like to get in super early and make the best of the potential your game has to offer. Give us a shot, describe your idea, and we will reach out.
                </p>

                <div className="games__promo__button -big">
                  <a href="#games-we-invest-in" className="games__promo__button__link">
                    <span className="games__promo__button__link__text">View games we invest in</span>
                  </a>
                  <div className="games__promo__button__shadow"></div>
                </div>

              </div>

            </div>

          </section>


          <section className="main-page-section -no-margin-top -gradient-background -type-3" id="games-we-invest-in">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  Games We Invest In
                </span>
              </h4>

              <p className="main-page-section__message -wide">
                As an equity fund, we support your game from many angles. We help build networks in the industry, showcase your game to the prominent experts, facilitate additional financing, and much more.
              </p>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">

                <div className="invest">
                  <div className="invest__left">
                    <Link to="/games/metaphora/" className="invest__item -metaphora">
                      <div className="invest__item__tags">
                        <div className="invest__item__tags__item">TOP-DOWN RPG</div>
                      </div>
                      <h5 className="invest__item__title">
                        Metaphora: The moonycat quet
                      </h5>
                      <p className="invest__item__text">
                        Metaphora is the name of a small hollow planet filled with fascinating and crazy wildlife. You play as LUX, a small star that has fallen from the sky, seeking a route home where he belongs.
                      </p>
                      <div className="invest__item__button">MORE ABOUT THE GAME</div>
                      <div className="invest__item__illustration -metaphora"></div>
                    </Link>
                  </div>
                  <div className="invest__right">
                    <Link to="/games/raji/" className="invest__item -raji">
                      <div className="invest__item__tags">
                        <div className="invest__item__tags__item">SIMULATION</div>
                        <div className="invest__item__tags__item">FPS</div>
                        <div className="invest__item__tags__item">4X</div>
                      </div>
                      <h5 className="invest__item__title">
                        Raji: An Ancient Epic
                      </h5>
                      <p className="invest__item__text">
                        Action-adventure game set in ancient India. Survive the demonic invasion as Raji, a young and agile street performer, who uses the blessings of the gods.
                      </p>
                      <div className="invest__item__button">MORE ABOUT THE GAME</div>
                      <div className="invest__item__illustration -raji"></div>
                    </Link>
                    <Link to="/games/retro-machina/" className="invest__item -retro-machina">
                      <div className="invest__item__tags">
                        <div className="invest__item__tags__item">RTS</div>
                        <div className="invest__item__tags__item">EXPLORATION</div>
                      </div>
                      <h5 className="invest__item__title">
                        Retro Machina
                      </h5>
                      <p className="invest__item__text">
                        Retro Machina is a single-player action game with puzzle elements in a retro-futuristic setting.
                      </p>
                      <div className="invest__item__button">MORE ABOUT THE GAME</div>
                      <div className="invest__item__illustration -retro-machina"></div>
                    </Link>
                  </div>
                </div>

              </div>

            </div>

          </section>


          <section className="main-page-section -no-margin-top -gradient-background -type-2" id="gametech-portfolio">

            <div className="center">

              <h4 className="main-page-section__title">
                <i></i>
                <span className="main-page-section__title__text">
                  Gametech <br/>
                  Companies Portfolio
                </span>
              </h4>

              <p className="main-page-section__message -wide">
                We are thrilled with the quality of technologies that are available in the backbone of the gaming industry. If your project appeals to the global gaming market, don’t hesitate to drop us a line.
              </p>

            </div>

            <div className="center -thin">

              <div className="main-page-section__content">
              
                <div className="gametech">
                  <Link to="https://ptah.pro/" className="gametech__item -ptah" target="_blank" rel="noopener noreferrer">
                    <h5 className="gametech__item__title -ptah">
                      Ptah
                    </h5>
                    <p className="gametech__item__text">
                      A website building tool tailored to game developers. Simple to master and powerful to use.
                    </p>
                    <div className="gametech__item__button">Visit website</div>
                  </Link>
                  {/* <Link to="https://pay.supergg.com/" className="gametech__item -paysuper" target="_blank" rel="noopener noreferrer">
                    <h5 className="gametech__item__title -paysuper">
                      Pay.Super!
                    </h5>
                    <p className="gametech__item__text">
                    A payment service provider in the gaming industry.
                    </p>
                    <div className="gametech__item__button">Visit website</div>
                  </Link> */}
                </div>

              </div>

            </div>

          </section>


        </div>


        <div className="main-page">

          <div className="scroll-arrow"></div>

          <section className="main-page__hero -no-overlay">

            <div className="center">

              <h2 className="main-page__hero__title -wide-letter-spacing">
                <span className="main-page__hero__title__link">Become</span><br className="main-page__hero__title__br"/> Super Powerful
              </h2>
              <p className="main-page__hero__message -min-margin-top">
                It doesn't take a radioactive spider or a freak accident to get Super powers.
              </p>

              <div className="games__promo__button">
                <button className="games__promo__button__link" data-role="call-to-action">
                  <span className="games__promo__button__link__text">Submit your project</span>
                </button>
                <div className="games__promo__button__shadow"></div>
              </div>

              <p className="main-page__hero__button-message">
                Just click the button and get the resources you need to have an edge on your competition.
              </p>
            
            </div>

          </section>

        </div>

      </Layout>
    );

  }

}

export default HomePage
